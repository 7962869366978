import IMask from 'imask';
import intlTelInput from 'intl-tel-input';
import libphonenumber from 'google-libphonenumber';

const $ = jQuery;

export const inputMask = () => {
  const phoneInputsContainers = document.querySelectorAll('.ginput_container_phone');
  let mask;

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const PNT = libphonenumber.PhoneNumberType;
  const PNF = libphonenumber.PhoneNumberFormat;

  if (phoneInputsContainers) {
    phoneInputsContainers.forEach(phoneInputsContainer => {
      let hiddenInput = phoneInputsContainer.querySelector('input[type="tel"]');
      hiddenInput.setAttribute('type', 'hidden');

      let input =  document.createElement('input');
      input.setAttribute('type', 'tel');
      input.setAttribute('class', 'iti__input');

      phoneInputsContainer.insertBefore(input, hiddenInput);

      if (hiddenInput.value) {
        let fullNumber = hiddenInput.value;
        let countryRegion = "";
        let simpleNumber = "";

        if (fullNumber.includes('invalid')) {
          let invalidNumber = fullNumber.split('-');
          countryRegion = invalidNumber.at(-1);
          simpleNumber = invalidNumber.at(0);
        } else {
          let numberProto = phoneUtil.parse(fullNumber, "");
          countryRegion = phoneUtil.getRegionCodeForNumber(numberProto);
          simpleNumber = phoneUtil.parseAndKeepRawInput(fullNumber, countryRegion).values_[2];
          countryRegion = phoneUtil.getRegionCodeForNumber(numberProto);
        }

        intlTelInput(input, {
          initialCountry: countryRegion,
          showSelectedDialCode: true,
        });

        setNumberAndMask(input, countryRegion);
        input.value = simpleNumber;
      } else {
        intlTelInput(input, {
          initialCountry: "auto",
          showSelectedDialCode: true,
          geoIpLookup: function(success) {
            $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
              let countryCode = (resp && resp.country) ? resp.country : "us";
              success(countryCode);
            });
          },
        });
      }

      let iti = window.intlTelInputGlobals.getInstance(input);
      let selectedData = "";

      input.addEventListener("countrychange", function() {
        selectedData = iti.getSelectedCountryData();
        let selectedCountry = selectedData.iso2.toUpperCase();

        setNumberAndMask(input, selectedCountry);
      });

      input.addEventListener('change', () => {
        selectedData = iti.getSelectedCountryData();

        if (input.value) {
          let inputValue = input.value;
          let validateNumber = phoneUtil.parse(inputValue, selectedData.iso2.toUpperCase());
          let isValid = phoneUtil.isValidNumber(validateNumber);

          if (isValid) {
            if (inputValue[0] == 0) {
              hiddenInput.value = "+" + selectedData.dialCode + ' ' + inputValue.slice(1).replace(/_/g, "");
            } else {
              hiddenInput.value = "+" + selectedData.dialCode + ' ' + inputValue.replace(/_/g, "");
            }
          } else {
            hiddenInput.value = inputValue.replace(/_/g, "") + '-invalid-' + selectedData.iso2;
          }
        }
      });
    });
  }

  function setNumberAndMask(input, selectedCountry) {
    let exampleNumber = phoneUtil.getExampleNumberForType(selectedCountry, PNT.MOBILE);

    let placeholder = phoneUtil.format(exampleNumber, PNF.NATIONAL);

    if (selectedCountry == "AT") {
      placeholder += '789';
    }

    input.placeholder = placeholder;

    let preparedMask = placeholder.replace(/\d/g, '0');

    const maskOptions = {
      mask: preparedMask,
    };

    if (mask) {
      mask.updateOptions(
        {
          mask: preparedMask,
        }
      );
    } else{
      mask = IMask(input, maskOptions);
    }
  }
}
